import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { graphql } from "gatsby";
import { Row, Col } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

import PageSection from "components/PageSection";
import RenderParagraph from "components/RenderParagraph";
import "./TermsDetails.scss";

export const fragment = graphql`
  fragment TermsMarkdownRemarkFragment on Frontmatter {
    anchor
    title
    subheader
    paragraphs {
      ...ParagraphFragment
    }
  }
`;

export const PARAGRAPH_IN_COLUMN = 58;

const TermsDetails = ({ className, frontmatter }) => {
  if (!frontmatter) return null;

  const { anchor, title, subheader, paragraphs } = frontmatter;

  return (
    <PageSection className={clsx("py-5", "terms-section", className)} id={anchor}>
      <Row className="mt-5">
        <Col className="mt-5" />
      </Row>
      <Row>
        <Col className="terms-texts text-sm-center text-md-left">
          <h1>{title}</h1>
          <h2>{subheader}</h2>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12} className="terms-texts text-justify text-md-left column-left">
          {paragraphs &&
            paragraphs
              .slice(0, PARAGRAPH_IN_COLUMN)
              .map((p) => <RenderParagraph p={p} key={uuidv4()} />)}
        </Col>
        <Col md={6} sm={12} className="terms-texts text-justify text-md-left column-right">
          {paragraphs &&
            paragraphs
              .slice(PARAGRAPH_IN_COLUMN)
              .map((p) => <RenderParagraph p={p} key={uuidv4()} />)}
        </Col>
      </Row>
    </PageSection>
  );
};

TermsDetails.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

TermsDetails.defaultProps = {
  className: null,
  frontmatter: null,
};

export default TermsDetails;
